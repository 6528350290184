/* CDN */
/*@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Silkscreen:wght@400;700&display=swap');*/
/* LOCAL */
@import url('./assets/fonts/urbanist.css');
@import url('./assets/fonts/silkscreen.css');
@import url('./assets/fonts/ldfcomic.css');

body {
  margin: 0;
}

#root {
  min-height: 100vh;
  margin: 0;

  background-size: cover;
}

a {
  color: #2ed500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Silkscreen', sans-serif;
  font-weight: 400;
}

.ribbonHeader {
  width: calc(100% + 40px);
  height: 60px;
  margin: 20px 0 auto;
  position: relative;
  /* color: #fff; */
  color: #f1e5c6;
  font-size: 48px;
  font-family: 'LDFComicSansBold', sans-serif;
  text-shadow: 2px 2px 4px #4b4686;
  -webkit-text-stroke: 1px #4b4686;
  text-align: center;
  text-transform: uppercase;
  background: #616998;
  left: -20px;
  box-shadow: 0px 16px 9px rgba(0, 0, 0, 0.2);
  animation: main 350ms;
}

.ribbonHeader i {
  position: absolute;
}

.ribbonHeader i:first-child,
.ribbonHeader i:nth-child(2) {
  position: absolute;
  left: -20px;
  bottom: -20px;
  z-index: -1;
  border: 20px solid transparent;
  border-right-color: #2f2c56;
  border-bottom-color: #55578f;
  -webkit-animation: edge 500ms;
  -moz-animation: edge 500ms;
  -ms-animation: edge 500ms;
  animation: edge 500ms;
}

.ribbonHeader i:nth-child(2) {
  left: auto;
  right: -20px;
  border-right-color: transparent;
  border-left-color: #2f2c56;
  border-bottom-color: #55578f;
}

.ribbonHeader i:nth-child(3),
.ribbonHeader i:last-child {
  width: 20px;
  bottom: -20px;
  left: -60px;
  z-index: -2;
  border: 30px solid #55578f;
  border-left-color: transparent;

  -webkit-animation: back 600ms;
  -moz-animation: back 600ms;
  -ms-animation: back 600ms;
  animation: back 600ms;

  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.ribbonHeader i:last-child {
  bottom: -20px;
  left: auto;
  right: -60px;
  border: 30px solid #55578f;
  border-right-color: transparent;

  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

@-webkit-keyframes main {
  0% {
    -webkit-transform: scaleX(0);
  }
  100% {
    -webkit-transform: scaleX(1);
  }
}

@-webkit-keyframes edge {
  0%,
  50% {
    -webkit-transform: scaleY(0);
  }
  100% {
    -webkit-transform: scaleY(1);
  }
}

@-webkit-keyframes back {
  0%,
  75% {
    -webkit-transform: scaleX(0);
  }
  100% {
    -webkit-transform: scaleX(1);
  }
}

@-moz-keyframes main {
  0% {
    -moz-transform: scaleX(0);
  }
  100% {
    -moz-transform: scaleX(1);
  }
}

@-moz-keyframes edge {
  0%,
  50% {
    -moz-transform: scaleY(0);
  }
  100% {
    -moz-transform: scaleY(1);
  }
}

@-moz-keyframes back {
  0%,
  75% {
    -moz-transform: scaleX(0);
  }
  100% {
    -moz-transform: scaleX(1);
  }
}

@keyframes main {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes edge {
  0%,
  50% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes back {
  0%,
  75% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.ribbonRounded {
  color: #f1e5c6;
  font-size: 32px;
  font-family: 'LDFComicSansBold', sans-serif;
  text-shadow: 2px 2px 4px #4b4686;
  -webkit-text-stroke: 1px #4b4686;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2);
}
.ribbonRounded {
  --r: 0.4em; /* control the ribbon shape (the radius) */

  position: absolute;
  top: 146px;
  inset-inline: calc(-1 * var(--r));
  text-align: center;
  line-height: 1.8;
  padding: calc(2 * var(--r)) 0.5em 0;
  border-radius: var(--r);
  --_g: 0 / var(--r) calc(2 * var(--r)) no-repeat;
  background:
    radial-gradient(100% 50% at right, #0005 98%, #0000 101%) 0 var(--_g),
    radial-gradient(100% 50% at left, #0005 98%, #0000 101%) 100% var(--_g),
    conic-gradient(at var(--r) calc(2 * var(--r)), #0000 25%, #616998 0) 0 0 / calc(100% - var(--r))
      100%;
}

.ribbonRounded .char {
  opacity: 0;
  animation: appear 0.5s forwards;
  text-transform: uppercase;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.level-data-markdown * > code {
  direction: ltr !important;
  display: inline-block;
}

.ace_scrollbar-v {
  display: none !important;
}

.ace_scrollbar-h {
  display: none !important;
}
